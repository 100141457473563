/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

// global.scss
//@import './node_modules/swiper/swiper';
@import './node_modules/swiper/swiper-bundle';
@import '~@ionic/angular/css/ionic-swiper';
@import '~node_modules/@mdi/font/scss/materialdesignicons';
@import "app-theme";
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600&display=swap');

.cursor-pointer{
  cursor: pointer;
}

@media screen and (max-width: 1500px) and (min-device-width : 640px) {
  .my-custom-modal-css {
    --height: 82vh;
    --width: 90% !important;
    border-radius: 12px; // Here the border radius
    &::part(content) {
      border-radius: 12px; // Here the border radius
      box-shadow: 0px 0px 20px 5px rgb(0 0 0 / 32%);
    }
  }
}

@media screen and (min-width: 1500px)  {
  .my-custom-modal-css {
    --height: 82vh;
    --width: 70% !important;
    border-radius: 12px; // Here the border radius
    &::part(content) {
      border-radius: 12px; // Here the border radius
      box-shadow: 0px 0px 20px 5px rgb(0 0 0 / 32%);
    }
  }
}

.swiper-wrapper {
  height: max-content;
  max-height: 30rem;

  width: 100%;

  .swiper-slide {
    // width: 100vw !important;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      height: 30rem;
      padding-bottom: 3rem;
    }
  }

  @media (max-width: 640px) {
    max-height: 25rem;

    .slide img {
      height: 25rem;
    }
  }
}

.primary-color{
  color: #ff4200 !important;
}
.color-black{
  color: #000000 !important;
}

.primary-button{
  background-color: #ff4200 !important;
  border-radius: 16px !important;
  color: #f0f0f0 !important;
  padding-left: 40px !important;
  padding-right: 40px !important;
}

.dark-button{
  background-color: #212121 !important;
  border-radius: 16px !important;
  color: #f0f0f0 !important;
  padding-left: 40px !important;
  padding-right: 40px !important;
}

.mw-container {
  //text-align: center;
  max-width: 1500px;
  margin: auto;
}

.rounded-select{
  background-color: #fafafa;
  border-radius: 24px !important;
  padding: 4px 12px !important;
  display: inline-block;
  width: 100%;
  margin: 16px;
}

.grey-bg{
  background-color: #f0f0f0 !important;
}

.large-icon{
  font-size: 32px;
  color: #767676;
}
.med-icon{
  font-size: 24px;
  color: #767676;
}

.card-box{
  padding: 4px !important;
  border-radius: 8px !important;
  width: 80px;
}

.card-input{
  background: none;
  outline: none;
  border: none;
  font-size: small;
}

.black-btn{
  border-radius: 16px !important;
  color: #f0f0f0;
  background-color: #212121;
  padding: 4px 8px;
}

.white-btn{
  border-radius: 16px !important;
  color: #212121;
  background-color: #f0f0f0;
  padding: 4px 8px;
}

.grey-bar{
  text-align: center;
  color: #f0f0f0;
  background-color: #f0f0f0;
  width: 100%;
  min-height: 100px;
  padding-top: 36px;
  padding-bottom: 36px;
}

.icon-32px{
  height: 32px;
  margin-bottom: 20px;
}

.gold-icon{
  color: #feb800;
  font-size: 32px;
}

.red-icon{
  color: #ff4200;
  font-size: 32px;
}

.black-bar{
  background-color: #212121;
  color: #f0f0f0;
  padding: 16px;
  text-align: center;
}

.product-container{
  padding: 16px;
  margin-top: 50px;
  border-radius: 16px;
  border: 1px solid #e1dede;
}

.price-box{
  cursor: pointer;
  border: 1px #e1dede solid;
  border-radius: 8px;
  padding: 8px 16px;
  margin: 8px;
  color: #767676;
}

.price-box:hover{
  border: 1px #212121 solid;
  color: #212121;
}


.price-box-selected{
  border: 1px #212121 solid;
  cursor: pointer;
  border-radius: 8px;
  padding: 8px 16px;
  margin: 8px;
  color: #212121;
}

.w-full{
  width: 100% !important;
}

.color-icon{
  height: 32px;
}

.color-box-selected{
  border-radius: 50%;
  border: 1px solid #9d9fa6;
  padding: 4px;
  //width: 38px;
  //height: 38px;
  display: inline-block;
}

.color-box:hover{
  border-radius: 50%;
  border: 1px solid #9d9fa6;
  padding: 4px;
  //width: 38px;
  //height: 38px;
  display: inline-block;
}

.color-box{
  border-radius: 50%;
  padding: 4px;
  display: inline-block;
}
